import { useEffect, useState } from "react";
import { removeEnrollment } from "../../features/enrollment/enrollSlice";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";

export const RemoveEnrollmentModal = ({ show, handleClose, enrollment }) => {
    const [status, setStatus] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const dispatch = useDispatch(); 

    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log("enrollment_id", enrollment.id);
      try {
        console.log("enrollmentData", enrollment.id);
        await dispatch(removeEnrollment({ id: enrollment.id }))
          .unwrap()
          .then((results) => {
            console.log("Enrollment Resp", results);
            const { data, msg, status } = results;
            if (status !== 200) {
              console.log("In Failed!!!!!");
              setAlertMsg(msg);
              setStatus("failed");
              setShowAlert(true);
            } else {
              setAlertMsg(
                "Successfully removed enrollment"
              );
              setStatus("success");
              setShowAlert(true);
            }
          });
      } catch (error) {
        console.log(error);
        setAlertMsg(
          "Oops! That didn't work. Please Provide all enrollment information and try again."
        );
        setShowAlert(true);
      } finally {
        e.target.reset();
      }
    };

    useEffect(() => {
      setTimeout(() => {
        setShowAlert(false);
        setShowError(false);
        setShowLoading(false);
      }, 5000);
    }, [dispatch]);
  return (
    <>
      {show && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-lg font-semibold text-gray-900">
                        Delete Student Enrollment
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          // stroke="currentColor"
                          className="size-8 rounded-md stroke-red-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                  {/* <Enroll /> */}
                  <div className="mx-auto max-w-lg">
                    <form className="mt-6" onSubmit={handleSubmit}>
                      <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                        <div className="col-span-full">
                          <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                            <h1 className="text-lg tracking-wide text-center font-medium">
                              Deleting Course Enrollment for{" "}
                              {enrollment.full_name}
                            </h1>
                          </div>
                        </div>
                        <div className="col-span-full">
                          {showAlert && (
                            <Alert
                              show={showAlert}
                              content={alertMsg}
                              type={status}
                            ></Alert>
                          )}
                        </div>
                        {/* <div className="col-span-full">
                          <p className="block text-xl font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md px-3 py-2">
                            Course Enrollment
                          </p>
                        </div> */}

                      </div>
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="mt-6 rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                        >
                          Delete
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};