import React, { useState, ReactNode } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../routes/routes";

export const Home = ({ children }) => {
  
  return (
    <>
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === "student" &&
            pages.map(({ path, element }) => (
              <Route exact path={path} element={element} />
            ))
        )}
      </Routes>
    </>
  );
};
