import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { fetchSessions, selectSessionById, selectSessions, selectSessionsStatus } from './sessionSlice';
import { AssignModal } from './AssignModal';
import { EditModal } from './EditModal';

const Sessions = () => {
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [sessionId, setSessionId] = useState("");
    const dispatch = useDispatch();
    
    const sessionsStatus = useSelector(selectSessionsStatus);
    const sessions = useSelector(selectSessions)

    const handleClick = (id) => {
      // console.log("sessId", e.target.value);
      setSessionId(id);
      setShowModal(true);
    }
    const showEdit = (id) => {
      // console.log("sessId", e.target.value);
      setSessionId(id);
      setShowEditModal(true);
    }

    useEffect(() => {
        if (sessionsStatus === "idle") {
            dispatch(fetchSessions());
        }
    }, [dispatch, sessionsStatus, sessions])
  return (
    <>
      {showModal && (
        <AssignModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          sessionId={sessionId}
        />
      )}
      {showEditModal && (
        <EditModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          sessionId={sessionId}
        />
      )}
      {!sessions && showLoading ? (
        <div className="mx-auto max-w-lg">
          <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
              Fetching Sessions
            </h5>
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Processing your request ...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-start justify-end w-full">
            <Link
              to="/dashboard/add-session"
              className="rounded-md border border-transparent bg-sky-950 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              onClick={() => {}}
            >
              Add New
            </Link>
          </div>
          {sessions.map((session, index) => (
            <div
              className="card bg-white  mx-auto max-w-full lg:flex lg:gap-x-16 lg:px-8"
              key={index}
            >
              <main className="px-1 sm:px-6 lg:flex-auto lg:px-0 lg:py-3 pb-4 pt-4">
                <div className="px-5 border border-gray-200 rounded-xl">
                  <div className="py-3 flex flex-col sm:flex-row justify-between items-center">
                    {/* <h1 className="text-2xl font-semibold text-gray-900">
                      Session Details
                    </h1> */}
                    {/* <Link
                      to="/dashboard/class-session-info"
                      state={{ sessionId: session.id }}
                      className="rounded-md  bg-blue-600 text-white text-sm font-medium px-4 py-2 hover:bg-gray-500"
                    >
                      View Details
                    </Link> */}
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="flex flex-col sm:flex-row justify-between items-center">
                      <h1 className="text-xl mx-3 font-medium text-gray-900">
                        Course:
                      </h1>
                      <h1 className="text-base font-base text-gray-900">
                        {session.course}
                      </h1>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between items-center">
                      <h1 className="text-xl mx-3 font-medium text-gray-900">
                        Class:
                      </h1>
                      <h1 className="text-base font-base text-gray-900">
                        {session.class}
                      </h1>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between items-center space-x-3">
                      <button
                        // href="course_edit_details.html"
                        type="submit"
                        className="rounded-md border border-gray-200 text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                        onClick={() => handleClick(session.id)}
                      >
                        Assign Tutor
                      </button>
                      <button
                        // href="course_edit_details.html"
                        type="submit"
                        className="rounded-md border bg-blue-600 text-sm font-medium text-white px-4 py-2 hover:bg-gray-400"
                        onClick={() => showEdit(session.id)}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-4">
                    <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200">
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Day</dt>
                        <dd className="text-gray-900">{session.day}</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Start Time</dt>
                        <dd className="text-gray-900">{session.start_at}</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">End Time</dt>
                        <dd className="text-gray-900">{session.end_at}</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Tutors</dt>
                        <dd className="text-gray-900">
                          {session.tutor ? session.tutor.name : "Not Assigned"}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </main>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Sessions
