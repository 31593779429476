import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  state: "idle",
  authType: ""
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      switch (action.payload) {
        case true:
          return { ...state, loggedIn: true };
        case false:
          return { ...state, loggedIn: false };
        default:
          return state;
      }
    },
    setAuthType: (state, action) => {
      return { ...state, authType: action.payload };
    },
  },
});

export const selectAuth = (state) => state.auth.loggedIn;
export const selectAuthType = (state) => state.auth.authType;
export const selectAuthState = (state) => state.auth.state;

export default authSlice.reducer;

export const { setLogin, setAuthType } = authSlice.actions;
