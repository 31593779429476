import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addRegisterAsync,
  fetchAttendance,
  selectRegistersError,
  selectRegisterStatus,
} from "../../features/attendance_registers/attendanceSlice";
import Alert from "../../components/common/alert";
import useScript from "../../hooks/useScript";
import { fetchSessions } from "../../features/sessions/sessionSlice";
import { SuccessSVG } from "../../components/common/SuccessSVG";

export const AttendanceRegister = () => {
  const [sessionInfo, setSessionInfo] = useState({});
  const [showStudents, setShowStudents] = useState(false);
  const [loading, setLoading] = useState(true);
  const [present, setPresent] = useState([]);
  const [absent, setAbsent] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [students, setStudents] = useState([]);
  const [marked, setMarked] = useState([]);
  const [markedStates, setMarkedStates] = useState([]);
  // const [students, setStudents] = useState([]);
  const [states, setStates] = useState([]);
  const [registerState, setRegisterState] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({});
  const [registertate, setState] = useState(false);
  const [sessionDate, setSessionDate] = useState("");
  const [updates, setUpdates] = useState({ present: [], absent: [] });
  const [searchParam, setSearchParam] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { session_id, date } = location.state || {};
  console.log("sessionId: ", session_id, "date:", date);

  const err = useSelector(selectRegistersError);
  const registersStatus = useSelector(selectRegisterStatus);

  useScript("https://cdn.jsdelivr.net/npm/flowbite@2.5.1/dist/flowbite.min.js");

  let today = new Date().toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const Tday = today.split(",")[1];

  const markPresent = (pos) => {
    const updatedStates = states.map((curState, index) =>
      index === pos ? "present" : curState
    );
    setStates(updatedStates);

    students.forEach((student, index) => {
      updatedStates.forEach((state, idx) => {
        if (idx === index && state !== "unmarked" && state === "present") {
          if (!present.find((item) => item === student.id)) {
            console.log("Pres ", student.id);
            setPresent([...present, student.id]);
            setPresentCount(presentCount + 1);
          }
          if (absent.find((item) => item === student.id)) {
            setAbsent(absent.filter((x) => x !== student.id));
            setAbsentCount(absentCount - 1);
          }
        }
      });
    });
    // setPresentCount(updatedStates.filter((val) => val === "present").length);
    // setAbsentCount(updatedStates.filter((val) => val === "absent").length);
  };

  const markAbsent = (pos) => {
    const updatedStates = states.map((curState, index) =>
      index === pos ? "absent" : curState
    );
    setStates(updatedStates);
    students.forEach((student, index) => {
      updatedStates.forEach((state, idx) => {
        if (idx === index && state !== "unmarked" && state === "absent") {
          if (!absent.find((id) => id === student.id)) {
            console.log("Abes", student.id);
            setAbsent([...absent, student.id]);
            setAbsentCount(absentCount + 1);
          }
          if (present.find((item) => item === student.id)) {
            setPresent(present.filter((x) => x !== student.id));
            setPresentCount(presentCount - 1);
          }
        }
      });
    });

    // setAbsentCount(updatedStates.filter((val) => val === "absent").length);
    // setPresentCount(updatedStates.filter((val) => val === "present").length);
  };

  const update = (value, state) => {
    // const value = e.target.value;
    // console.log("id:", value, "state", state);
    switch (state) {
      case "absent":
        setAbsentCount(absentCount + 1);
        setPresentCount(presentCount - 1);
        if (!updates.absent.find((id) => id === value)) {
          // console.log("curr", updates.absent)
          // const newArr = [...updates.absent, value];
          // console.log("newArr", newArr);
          setUpdates({ ...updates, absent: [...updates.absent, value] });
          setMarkedStates(
            markedStates.map((item) =>
              item.id === value ? { ...item, state: "absent" } : item
            )
          );
        }
        if (updates.present.find((item) => item === value)) {
          // const newArr = updates.present.filter((x) => x !== value);
          setUpdates({
            ...updates,
            present: updates.present.filter((x) => x !== value),
          });
        }

        break;
      case "present":
        setPresentCount(presentCount + 1);
        setAbsentCount(absentCount - 1);
        if (!updates.present.find((id) => id === value)) {
          console.log("curr", updates.present);
          const newPresent = [...updates.present, value];
          console.log("newArr", newPresent);
          setUpdates({ ...updates, present: newPresent });
          setMarkedStates(
            markedStates.map((item) =>
              item.id === value ? { ...item, state: "present" } : item
            )
          );
        }
        if (updates.absent.find((item) => item === value)) {
          setUpdates({
            ...updates,
            absent: updates.absent.filter((x) => x !== value),
          });
        }

        break;
      default:
        break;
    }
  };

  const handleDateSelect = (e) => {
    console.log(sessionInfo.attendance);
    let selectedDay = new Date(e.target.value).toLocaleDateString("es-Es");
    console.log("init Day", selectedDay);
    if (selectedDay.split("/")[0].length === 1) {
      selectedDay = `0${selectedDay}`;
    }
    console.log("Day", selectedDay);
    setSessionDate(selectedDay);
    const attendance = sessionInfo.attendance[selectedDay];
    console.log("selectedDay", selectedDay, attendance);
    if (attendance) {
      // setRegisterState("draft");
      const markedStates = attendance.map((item) => ({
        id: item.id,
        state: item.attendance,
      }));
      attendance.some(({ status }) => {
        if (status === "draft") {
          setRegisterState("draft");
          return true;
        } else {
          setRegisterState("saved");
          return true;
        }
      });
      console.log("markedStates:", markedStates);
      setPresentCount(
        markedStates.filter((item) => item.state === "present").length
      );
      setAbsentCount(
        markedStates.filter((item) => item.state === "absent").length
      );
      setMarkedStates(markedStates);
      const markedStudentIDs = attendance.map((item) => item.student_id);
      // setMarked(markedStudentIDs);
      console.log("MarkedIDs", markedStudentIDs);
      const markedStudents = sessionInfo.students.filter((item) =>
        markedStudentIDs.includes(item.id)
      );
      setMarked(markedStudents);
      console.log("Marked STudents", markedStudents);
      const unmarkedStudents = sessionInfo.students.filter(
        (item) => !markedStudentIDs.includes(item.id)
      );
      console.log("Not Marked", unmarkedStudents);
      setStates(new Array(unmarkedStudents.length).fill("unmarked"));
      setStudents(unmarkedStudents);
      setShowStudents(true);
    } else {
      setRegisterState("new");
      setMarkedStates([]);
      setMarked([]);
      setStates(new Array(sessionInfo.students.length).fill("unmarked"));
      setStudents(sessionInfo.students);
      setShowStudents(true);
    }
  };

  const saveDraft = async () => {
    console.log("present", present);
    console.log("absent", absent);

    console.log("updatesPresent", updates.present);
    console.log("updatesAbsent", updates.absent);
    // if (present.length !== 0 || absent.length !== 0) {
    try {
      const payload = {
        present: present,
        absent: absent,
        session_id: sessionInfo.id,
        day: sessionDate,
        status: "draft",
      };
      if (updates.present.length !== 0 || updates.absent.length !== 0) {
        payload.updates = updates;
      }
      const { data, msg, status } = await dispatch(
        addRegisterAsync(payload)
      ).unwrap();
      if (status !== 200) {
        setAlertMsg({
          ...alertMsg,
          msg: msg,
          type: "failed",
        });
        setShowAlert(true);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
        type: "failed",
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: err,
        type: "failed",
      });
      setShowAlert(true);
      // e.target.reset();
      // setAttendance({});
    }
    // } else {
    //   setShowAlert(true);
    // }
  };

  const getSession = useCallback(async () => {
    try {
      await dispatch(fetchSessions({ params: { id: session_id } }))
        .unwrap()
        .then(({ data, msg, status }) => {
          console.log(msg);
          setLoading(false);
          if (status !== 200) {
            setAlertMsg((alert) => ({ ...alert, msg: msg, type: "failed" }));
            setShowAlert(true);
            setTimeout(() => {
              navigate("/dashboard");
            }, 10000);
          } else {
            setSessionInfo(data);
            if (data.attendance.length !== 0 && sessionDate !== "") {
              const attendance = data.attendance[sessionDate];
              if (attendance) {
                attendance.some(({ status }) => {
                  if (status === "draft") {
                    setRegisterState("draft");
                    return true;
                  } else {
                    setRegisterState("saved");
                    return true;
                  }
                });
                const markedStates = attendance.map((item) => ({
                  id: item.id,
                  state: item.attendance,
                }));
                console.log("markedStates:", markedStates);
                setPresentCount(
                  markedStates.filter((item) => item.state === "present").length
                );
                setAbsentCount(
                  markedStates.filter((item) => item.state === "absent").length
                );
                setMarkedStates(markedStates);
                const markedStudentIDs = attendance.map(
                  (item) => item.student_id
                );
                // setMarked(markedStudentIDs);
                console.log("MarkedIDs", markedStudentIDs);
                const markedStudents = data.students.filter((item) =>
                  markedStudentIDs.includes(item.id)
                );
                console.log("passsss");
                setMarked(markedStudents);
                console.log("Marked STudents", markedStudents);
                const unmarkedStudents = data.students.filter(
                  (item) => !markedStudentIDs.includes(item.id)
                );
                console.log("Not Marked", unmarkedStudents);
                setStates(new Array(unmarkedStudents.length).fill("unmarked"));
                setStudents(unmarkedStudents);
                setShowStudents(true);
              } else {
                setRegisterState("new");
                setMarkedStates([]);
                setMarked([]);
                setStates(new Array(data.students.length).fill("unmarked"));
                setStudents(data.students);
                setShowStudents(true);
              }
            }
          }
        });
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      setAlertMsg((alert) => ({
        ...alert,
        msg: error.response,
        type: "failed",
      }));
      setShowAlert(true);
    }
  }, [dispatch, session_id, navigate, sessionDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const present = [];
    // const absent = [];
    // students.forEach((student, index) => {
    //   states.forEach((state, idx) => {
    //     if (idx === index) {
    //       if (state) {
    //         present.push(student.id);
    //       } else {
    //         absent.push(student.id);
    //       }
    //     }
    //   });
    // });
    // const attendance = {
    //   session_id: sessionInfo.id,
    //   present: present,
    //   absent: absent,
    // };
    // const attendance = states.map((state, idx) => {
    //   const present = students.map((student, index) =>
    //     index === idx && state ? student.id : ""
    //   );
    //   const absent = students.map((student, index) =>
    //     index === idx && !state ? student.id : ""
    //   );
    //   return
    // });
    console.log("present", present);
    console.log("absent", absent);

    console.log("updatesPresent", updates.present);
    console.log("updatesAbsent", updates.absent);

    // if (present.length !== 0 || absent.length !== 0) {
    try {
      const payload = {
        present: present,
        absent: absent,
        session_id: sessionInfo.id,
        day: sessionDate,
        status: "final",
      };
      if (updates.present.length !== 0 || updates.absent.length !== 0) {
        payload.updates = updates;
      }
      await dispatch(addRegisterAsync(payload))
        .unwrap()
        .then(({ data, msg, status }) => {
          console.log("submit response", msg);
          setAlertMsg({
            ...alertMsg,
            msg: msg,
          });
          setShowAlert(true);
          if (status === 200) {
            getSession();
            e.target.reset();
          }
        });
    } catch (error) {
      console.log(error);
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: err,
      });
      setShowAlert(true);
      e.target.reset();
      // setAttendance({});
    }
    // } else {
    //   setShowAlert(true);
    // }
  };

  // const getAttendance = async (session_id, date) => {
  //   try {
  //     const { data, msg, status } = await fetchAttendance({
  //       id: session_id,
  //       day: date,
  //     })
  //       .unwrap()
  //       .then(() => {
  //         if (status !== 200) {
  //           setAlertMsg({ ...alertMsg, msg: msg, type: "failed" });
  //           setShowAlert(true);
  //           setTimeout(() => {
  //             navigate("/dashboard");
  //           }, 10000);
  //         } else {
  //           setSessionInfo(data);
  //         }
  //       });
  //   } catch (error) {
  //     setAlertMsg({ ...alertMsg, msg: error.response, type: "failed" });
  //     setShowAlert(true);
  //   }
  // };

  useEffect(() => {
    if (date) {
      setSessionDate(date);
    }
    // setSessionInfo(session);
    // setStudents(sessionInfo.students);
    getSession();
    // }
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 5000);
  }, [date, getSession, showAlert]);
  return (
    <>
      {/* <div className="pb-4 pt-12 lg:flex">
          <a href="/">
            <span className="sr-only">IYF Free Academy</span>
            <img
              src="../assets/png/iyflogo-s7.png"
              alt=""
              className="h-12 lg:h-20 w-auto"
            />
          </a><div className="px-4 sm:px-6 lg:px-8">
        </div> */}
      {/* <SuccessSVG/> */}
      <section className="justify-items-center flex justify-center overflow-y-auto pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0">
        {/* <!-- Start Student Data --> */}
        {loading && !sessionInfo ? (
          <div className="mx-auto max-w-lg">
            <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                Enrollment
              </h5>
              <div
                role="status"
                className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Processing your request ...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 lg:w-1/2 justify-center">
            <div className="p-4 bg-white border border-gray-200 rounded-tl-xl rounded-tr-xl col-span-full">
              <div className="flex justify-start items-start">
                <button
                  onClick={() => navigate(-1) || navigate("/dashboard")}
                  className="py-1 px-2 bg-gray-100 mr-4 rounded-md
                hover:bg-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                    />
                  </svg>
                </button>
                <div>
                  <h1 className="text-2xl font-bold">{sessionInfo.class}</h1>
                  <p>
                    {today}, {sessionInfo.start_at} - {sessionInfo.end_at}{" "}
                    Session
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Sticky Section --> */}
            <div className="sticky top-0 z-10 bg-white border border-t-0 border-gray-200 rounded-bl-xl rounded-br-xl col-span-full">
              <div className="flex justify-between">
                {/* <!--Count Present--> */}
                <div className="w-1/2 text-center p-4 border-r border-gray-200">
                  <h1 className="text-3xl font-bold">{presentCount}</h1>
                  <p>Present</p>
                </div>
                {/* <!--Count Absent--> */}
                <div className="w-1/2 text-center p-4">
                  <h1 className="text-3xl font-bold">{absentCount}</h1>
                  <p>Absent</p>
                </div>
              </div>
            </div>
            <div className="mt-5 col-span-full">
              {showAlert && (
                <Alert
                  show={showAlert}
                  content={alertMsg.msg}
                  type={registersStatus}
                ></Alert>
              )}
            </div>
            <form onSubmit={handleSubmit} className="col-span-full mt-5">
              <div className="flex justify-center relative max-w-sm">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  required
                  type="date"
                  id="day"
                  name="day"
                  // value={Tday}
                  defaultValue={sessionDate}
                  min="01-01-2024"
                  max="31-12-2024"
                  onChange={handleDateSelect}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="dd-MM-yyyy"
                />
              </div>

              {/* {registerState === ""} */}
              {showStudents && (
                <div className="">
                  {marked.length !== 0 && (
                    <div className="mt-4 bg-white p-4 shadow sm:rounded-md">
                      <div className="overflow-hidden flex justify-between items-center py-2">
                        <h1 className="text-2xl mb-5  font-bold">Marked</h1>
                        <input
                          type="text"
                          x-model="search"
                          placeholder="Search names, IDs, or Phone No..."
                          className="px-4 py-2 border-3 border-gray-500 rounded-md w-1/2"
                          onChange={(e) => setSearchParam(e.target.value)}
                        />
                      </div>
                      <ul className="divide-y divide-solid divide-gray-200">
                        {marked
                          .filter((item) => {
                            return searchParam.toLowerCase() === ""
                              ? item
                              : item.full_name
                                  .toLowerCase()
                                  .includes(searchParam) ||
                                  item.msisdn.includes(searchParam) ||
                                  item.registration_no
                                    .toLowerCase()
                                    .includes(searchParam);
                          })
                          .map((student, index) => (
                            <li
                              className="overflow-hidden flex justify-between items-center py-2"
                              key={index}
                            >
                              <div>
                                <h4 className="block text font-bold text-gray-700">
                                  {student.full_name}
                                </h4>
                                <p className="block text-sm font-medium text-gray-700">
                                  {student.registration_no}
                                </p>
                                <p className="block text-sm font-medium text-gray-700">
                                  {student.msisdn}
                                </p>
                              </div>
                              <div
                                x-data="{ activeState: entry.status, scrollY: 0 }"
                                className="space-x-4 flex justify-end"
                              >
                                {/* <!-- Present Button --> */}
                                <button className="text-sm" type="button">
                                  {markedStates[index].state !== "present" ? (
                                    <svg
                                      type="disabled"
                                      className="h-10 w-10"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() => {
                                        update(
                                          markedStates[index].id,
                                          "present"
                                        );
                                      }}
                                    >
                                      <rect
                                        width="32"
                                        height="32"
                                        rx="16"
                                        fill="#D7D7D7"
                                      />
                                      <path
                                        d="M24 10.5L13 21.5L8 16.5"
                                        stroke="#8D8D8D"
                                        strokeWidth="2.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      type="disabled"
                                      className="h-10 w-10"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="32"
                                        height="32"
                                        rx="16"
                                        fill="#7EE86C"
                                      />
                                      <path
                                        d="M24 10.5L13 21.5L8 16.5"
                                        stroke="black"
                                        strokeWidth="2.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}
                                </button>
                                {/* <!-- Absent Button --> */}
                                <button className="text-sm" type="button">
                                  {markedStates[index].state === "absent" ? (
                                    <svg
                                      type="disabled"
                                      className="h-10 w-10"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="32"
                                        height="32"
                                        rx="16"
                                        fill="#F61616"
                                      />
                                      <path
                                        d="M22 10L10 22"
                                        stroke="black"
                                        strokeWidth="2.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M10 10L22 22"
                                        stroke="black"
                                        strokeWidth="2.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      type="disabled"
                                      className="h-10 w-10"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() => {
                                        update(
                                          markedStates[index].id,
                                          "absent"
                                        );
                                      }}
                                    >
                                      <rect
                                        width="32"
                                        height="32"
                                        rx="16"
                                        fill="#D7D7D7"
                                      />
                                      <path
                                        d="M22 10L10 22"
                                        stroke="#8D8D8D"
                                        strokeWidth="2.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M10 10L22 22"
                                        stroke="#8D8D8D"
                                        strokeWidth="2.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}
                                </button>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                  <div className="mt-4 bg-white p-4 shadow sm:rounded-md">
                    <div className="overflow-hidden flex justify-between items-center py-2">
                      <h1 className="text-2xl mb-5 font-bold text-">
                        Unmarked
                      </h1>
                      <input
                        type="text"
                        x-model="search"
                        placeholder="Search names, IDs, or Phone No..."
                        className="px-4 py-2 border-3 border-gray-500 rounded-md w-1/2"
                        onChange={(e) => setSearchParam(e.target.value)}
                      />
                    </div>
                    <ul className="divide-y divide-solid divide-gray-200">
                      {students
                        .filter((item) => {
                          return searchParam.toLowerCase() === ""
                            ? item
                            : item.full_name
                                .toLowerCase()
                                .includes(searchParam) ||
                                item.msisdn.includes(searchParam) ||
                                item.registration_no
                                  .toLowerCase()
                                  .includes(searchParam);
                        })
                        .map((student, index) => (
                          <li
                            className="overflow-hidden flex justify-between items-center py-2"
                            key={index}
                          >
                            <div>
                              <h4 className="block text font-bold text-gray-700">
                                {student.full_name}
                              </h4>
                              <p className="block text-sm font-medium text-gray-700">
                                {student.registration_no}
                              </p>
                              <p className="block text-sm font-medium text-gray-700">
                                {student.msisdn}
                              </p>
                            </div>
                            <div
                              x-data="{ activeState: entry.status, scrollY: 0 }"
                              className="space-x-4 flex justify-end"
                            >
                              {/* <!-- Present Button --> */}
                              <button className="text-sm" type="button">
                                {states[index] === "unmarked" ||
                                states[index] === "absent" ? (
                                  <svg
                                    type="button"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                      markPresent(index);
                                    }}
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#D7D7D7"
                                    />
                                    <path
                                      d="M24 10.5L13 21.5L8 16.5"
                                      stroke="#8D8D8D"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#7EE86C"
                                    />
                                    <path
                                      d="M24 10.5L13 21.5L8 16.5"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </button>
                              {/* <!-- Absent Button --> */}
                              <button className="text-sm" type="button">
                                {states[index] === "unmarked" ||
                                states[index] === "present" ? (
                                  <svg
                                    type="button"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                      markAbsent(index);
                                    }}
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#D7D7D7"
                                    />
                                    <path
                                      d="M22 10L10 22"
                                      stroke="#8D8D8D"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10 10L22 22"
                                      stroke="#8D8D8D"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    type="button"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#F61616"
                                    />
                                    <path
                                      d="M22 10L10 22"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10 10L22 22"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                                {/* {!states[index] ? (
                              <svg
                                type="button"
                                className="h-10 w-10"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                  handleClick(index);
                                }}
                              >
                                <rect
                                  width="32"
                                  height="32"
                                  rx="16"
                                  fill="#F61616"
                                />
                                <path
                                  d="M22 10L10 22"
                                  stroke="black"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10 10L22 22"
                                  stroke="black"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                type="button"
                                className="h-10 w-10"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                  handleClick(index);
                                }}
                              >
                                <rect
                                  width="32"
                                  height="32"
                                  rx="16"
                                  fill="#D7D7D7"
                                />
                                <path
                                  d="M22 10L10 22"
                                  stroke="#8D8D8D"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10 10L22 22"
                                  stroke="#8D8D8D"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )} */}
                              </button>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}

              <div className="fixed bottom-4 left-0 w-full space-x-4 flex justify-center">
                <button
                  type="submit"
                  value="save"
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Save and Submit
                </button>
                <button
                  type="button"
                  onClick={saveDraft}
                  className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md shadow-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
                >
                  Save as Draft
                </button>
              </div>
            </form>
          </div>
        )}
      </section>
    </>
  );
};
