import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchEnrollments = createAsyncThunk(
  "/enrollments/fetchEnrollments",
  async (params) => {
    const { data: response } = await Apis.getEnrollments(params);
    // console.log("fetchEnrollments", response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

// export const fetchEnrollment = createAsyncThunk(
//   "/enrollments/fetchEnrollment",
//   async (params) => {
//     const { data: response } = await Apis.getEnrollments(params);
//     // console.log("data", response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const enrollStudent = createAsyncThunk(
  "/enrollment/enrollStudent",
  async (enrollData) => {
    // console.log("enrollData", enrollData);

    // if (response.data) {
    //   return response.data;
    // } else {
    //   return [];
    // }

    try {
      // const { status, data } = await Apis.makePayment(paymentInfo);
      const response = await Apis.enroll(enrollData);
      const { data } = response;
      return { data: data.data, msg: data.msg, status: 200 };
    } catch (error) {
      // console.log("Error>>>", error);
      const { data: response } = error.response;
      // console.log("Response >>>", response);
      const { status } = error;
      // console.log("status >>>", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);
export const updateEnrollment = createAsyncThunk(
  "/enrollment/updateEnrollment",
  async (updates) => {
    try {
      const response = await Apis.updateEnrollment(updates);
      const { data } = response;
      return { data: data.data, msg: data.msg, status: 200 };
    } catch (error) {
      // console.log("Error>>>", error);
      const { data: response } = error.response;
      // console.log("Response >>>", response);
      const { status } = error;
      // console.log("status >>>", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);
export const removeEnrollment = createAsyncThunk(
  "/enrollment/removeEnrollment",
  async (removes) => {
    try {
      const response = await Apis.removeEnrollment(removes);
      const { data } = response;
      return { data: data.data, msg: data.msg, status: 200 };
    } catch (error) {
      // console.log("Error>>>", error);
      const { data: response } = error.response;
      console.log("Response >>>", response);
      const { status } = error;
      // console.log("status >>>", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);

// export const searchStudent = createAsyncThunk(
//   "/enrollment/searchRegistrationStatus",
//   async (param) => {
//     const { data: response } = await Apis.getRegistration(param);
//     // console.log(response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const verifyEnrollment = createAsyncThunk(
  "enrollment/verifyEnrollment",
  async (verificationData) => {
    try {
      const {
        data: { data, msg, success },
        status,
      } = await Apis.verifyEnrollment(verificationData);
      // console.log("Response", response);
      if (!success) {
        return { data: null, msg: msg, status: status };
      }
      return { data: data, msg: msg, status: status };
    } catch (error) {
      console.log("verify error", error);
      const {
        response: { data },
        status,
      } = error;
      return { data: null, msg: data.msg, status: status };
    }
  }
);

export const pay = createAsyncThunk(
  "enrollment/pay",
  async (paymentInfo) => {
    try {
      const {data: { data, msg}, status} = await Apis.makePayment(paymentInfo);
      console.log("Pay Response", data);
      // const { data, msg, status } = response.data;

      return { data: data, msg: msg, status: status };
    } catch (error) {
      //   console.log("Error", error);
      console.log("Error>>>", error);
      const { response: { data } , status } = error;
      console.log("Response >>>", data);
        console.log("status >>>", status);
      return { data: null, msg: data.msg, status: status };
    }
  }
);

const initialState = {
  enrollments: [],
  status: "idle",
  error: null,
  enrollment: {},
  enrollmentResponse: {},
  studentInfo: {},
  verificationResponse: {},
  paymentResponse: {}
};

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    setEnrollResponse: (state, action) => {
      return { ...state, enrollmentResponse: action.payload };
    },
    setEnrollData: (state, action) => {
      return { ...state, enrollment: action.payload };
    },
    setStudentInfo: (state, action) => {
      return { ...state, studentInfo: action.payload };
    },
    setPayResponse: (state, action) => {
      return { ...state, paymentResponse: action.payload };
    },
  },
  selectors: {
    selectEnrollStatus: (state) => state.status,
    selectEnrollError: (state) => state.error,
    selectEnrollData: (state) => state.enrollment,
    selectEnrollments: (state) => state.enrollments,
    selectStudentInfo: (state) => state.studentInfo,
    selectEnrollResp: (state) => state.enrollmentResponse,
    selectVerification: (state) => state.verificationResponse,
    selectPayResponse: (state) => state.paymentResponse,
  },
  extraReducers(builder) {
    builder
      .addCase(enrollStudent.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        console.log("In fulfilled", msg);
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.enrollmentResponse = {
            enrollment_id: data.enrollment_id,
            student_msisdn: data.student_msisdn,
          };
        }
      })
      .addCase(enrollStudent.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(enrollStudent.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateEnrollment.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.enrollments = state.enrollments.map((enrollment) => {
            if (enrollment.id === data.id) {
              const updated = { ...enrollment, ...data };
              console.log("updates::::", updated);
              return updated;
            } else {
              return enrollment;
            }
          });
        }
      })
      .addCase(updateEnrollment.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(updateEnrollment.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(verifyEnrollment.fulfilled, (state, action) => {
        state.status = "success";
        state.verificationResponse = action.payload;
      })
      .addCase(verifyEnrollment.pending, (state) => {
        state.status = "pending";
      })
      .addCase(verifyEnrollment.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(pay.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.paymentResponse = {
            payment_request_id: data.payment_request_id,
            student_msisdn: data.student_msisdn,
          };
        }
      })
      .addCase(pay.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(pay.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchEnrollments.fulfilled, (state, action) => {
        state.status = "success";
        if (Array.isArray(action.payload)) {
          state.enrollments = action.payload;
        }else{
          state.enrollment = action.payload;

        }
      })
      .addCase(fetchEnrollments.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchEnrollments.rejected, (state, action) => {
        state.status = "failed";
      });
      // .addCase(fetchEnrollment.fulfilled, (state, action) => {
      //   state.status = "success";

      //   state.enrollment = action.payload;
      // })
      // .addCase(fetchEnrollment.pending, (state, action) => {
      //   state.status = "pending";
      // })
      // .addCase(fetchEnrollment.rejected, (state, action) => {
      //   state.status = "failed";
      // });
  },
});
export const { setEnrollData, setEnrollResponse, setStudentInfo, setPayResponse } =
  enrollmentSlice.actions;
export const {
  selectEnrollStatus,
  selectEnrollData,
  selectStudentInfo,
  selectEnrollResp,
  selectEnrollError,
  selectVerification,
  selectPayResponse,
  selectEnrollments,
} = enrollmentSlice.selectors;


export default enrollmentSlice.reducer;
