import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { routes } from "../routes/routes";
import { getOidToken, getUserDetails } from "../utils/helpers/authHelpers";
import { useDispatch } from "react-redux";
import { setLogin } from "../features/auth/authSlice";
import { Header, Stats } from "../components/Header";
import { useEffect, useState } from "react";
const RouteGuard = ({ children }) => {
  const token = getOidToken();
  return token ? children : <Navigate to="/auth/login" />;
};

export const Dashboard = () => {
  const [authType, setAuthType] = useState(null);
  const authToken = getOidToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (authToken) {
      const user = getUserDetails();
      dispatch(setLogin(true));
      setAuthType(user.user_type);
    } else {
      navigate("/auth/login");
    }
  }, [authToken, dispatch, navigate]);
  switch (authType) {
    case "admin":
      return (
        <>
          <RouteGuard>
            <Header />
            <Sidebar authType={authType} />
            <Stats />
            <div className="lg:pl-72">
              <main className="py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <Routes>
                    {routes.map(
                      ({ layout, pages }) =>
                        layout === "admin" &&
                        pages.map(({ path, element }) => (
                          <Route exact path={path} element={element} />
                        ))
                    )}
                  </Routes>
                </div>
              </main>
            </div>
          </RouteGuard>
        </>
      );
    case "cashier":
      return (
        <>
          <RouteGuard>
            <Header />
            <Sidebar authType={authType} />
            <Stats />
            <div className="lg:pl-72">
              <main className="py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <Routes>
                    {routes.map(
                      ({ layout, pages }) =>
                        layout === "finance" &&
                        pages.map(({ path, element }) => (
                          <Route exact path={path} element={element} />
                        ))
                    )}
                  </Routes>
                </div>
              </main>
            </div>
          </RouteGuard>
        </>
      );
    case "tutor":
      return (
        <>
          <RouteGuard>
            <Header />
            <Sidebar authType={authType} />
            <div className="lg:pl-72">
              <main className="py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <Routes>
                    {routes.map(
                      ({ layout, pages }) =>
                        layout === "tutor" &&
                        pages.map(({ path, element }) => (
                          <Route exact path={path} element={element} />
                        ))
                    )}
                  </Routes>
                </div>
              </main>
            </div>
          </RouteGuard>
        </>
      );

    default:
      break;
  }
  return (
    <>
      <RouteGuard>
        <Header />
        {(authType === "admin" || authType === "cashier") && <Stats />}
        {(authType === "admin" || authType === "cashier") && (
          <Sidebar authType={authType} />
        )}

        {authType === "admin" ? (
          <div className="lg:pl-72">
            <main className="py-10">
              <div className="px-4 sm:px-6 lg:px-8">
                <Routes>
                  {routes.map(
                    ({ layout, pages }) =>
                      layout === "admin" &&
                      pages.map(({ path, element }) => (
                        <Route exact path={path} element={element} />
                      ))
                  )}
                </Routes>
              </div>
            </main>
          </div>
        ) : authType === "cashier" ? (
          <div className="lg:pl-72">
            <main className="py-10">
              <div className="px-4 sm:px-6 lg:px-8">
                <Routes>
                  {routes.map(
                    ({ layout, pages }) =>
                      layout === "finance" &&
                      pages.map(({ path, element }) => (
                        <Route exact path={path} element={element} />
                      ))
                  )}
                </Routes>
              </div>
            </main>
          </div>
        ) : (
          <Routes>
            {routes.map(
              ({ layout, pages }) =>
                layout === "tutor" &&
                pages.map(({ path, element }) => (
                  <Route exact path={path} element={element} />
                ))
            )}
          </Routes>
        )}
      </RouteGuard>
    </>
  );
};
