import React, { useEffect, useState } from 'react'
import { Enroll } from './enroll';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, selectCourses, selectCourseStatus } from '../../features/courses/courseSlice';
import { enrollStudent, selectEnrollStatus, updateEnrollment } from '../../features/enrollment/enrollSlice';
import Alert from '../../components/common/alert';

const UpdateEnrollment = ({ show, handleClose, enrollment }) => {
  const [sessionChecked, setSessionChecked] = useState([]);
  const [updates, setUpdates] = useState({});
  const [showClasses, setShowClasses] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [sessionsInput, setSessionsInput] = useState([]);
  const [courseItem, setCourseItem] = useState({});
  const [classItem, setClassItem] = useState();

  const [status, setStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParam, setSearchParamParam] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const newStudent = useSelector(sele);
  const courseStatus = useSelector(selectCourseStatus);
  const enrollStatus = useSelector(selectEnrollStatus);
  const courses = useSelector(selectCourses);

    const handleCourseInput = (e) => {
      setShowSessions(false);
      const course = courses.find(
        (course) => course.id === parseInt(e.target.value)
      );
      if (course && course.classes.length !== 0) {
        setCourseItem(course);
        setClasses(course.classes);
        setShowClasses(true);
      }
    };

    const handleEnrollmentInput = (e) => {
      const { name, value } = e.target;
      setUpdates({ ...updates, [name]: value });
      // console.log("updates", updates);
    };
    const handleSessionInputChange = (position) => {
      const updatedCheckedState = sessionChecked.map((item, index) =>
        index === position ? !item : item
      );
      setSessionChecked(updatedCheckedState);
      const sessionsList = updatedCheckedState.reduce(
        (ids, currentState, index) => {
          if (currentState === true) {
            return [...ids, sessions[index].id];
          }
          return ids;
        },
        []
      );
      setUpdates({ ...updates, sessions: sessionsList });
    };

    const handleClassInputChange = (e) => {
      console.log("course", courseItem);
      const class_ = courseItem.classes.find(
        (item) => item.id === parseInt(e.target.value)
      );
      if (class_) {
        setClassItem(class_);
        setSessions(class_.sessions);
        setSessionChecked(new Array(class_.sessions.length).fill(false));
        setShowSessions(true);
        setUpdates({
          ...updates,
          class_id: parseInt(e.target.value),
        });
      } else {
        setShowSessions(false);
      }
    };
     const handleSubmit = async (e) => {
       e.preventDefault();
       console.log("Status", enrollment);
       try {
         const payload = {
           ...updates,
           student_id: enrollment.student_id,
           id: enrollment.id,
         };
         console.log("enrollmentData", payload);
         await dispatch(updateEnrollment(payload))
           .unwrap()
           .then((results) => {
             console.log("Enrollment Resp", results);
             const { data, msg, status } = results;
             if (status !== 200) {
               console.log("In Failed!!!!!");
               setAlertMsg(msg);
               setShowClasses(false);
               setShowSessions(false);
               setStatus("failed");
               setShowAlert(true);
              } else {
                setShowClasses(false);
                setShowSessions(false);
                setAlertMsg(`Successfully updated enrollment to ${data.course}/${data.class}`);
                setStatus("success");
               setShowAlert(true);
             }
           });
       } catch (error) {
        console.log(error)
         setAlertMsg(
           "Oops! That didn't work. Please Provide all enrollment information and try again."
         );
         setShowAlert(true);
       } finally {
         e.target.reset();
       }
     };

    useEffect(() => {
      console.log("fired!")
      if (!courses.length) {
        dispatch(fetchCourses());
      }
      setTimeout(() => {
        setShowAlert(false);
        setShowError(false);
        setShowLoading(false);
      }, 5000);
    }, [courses.length, dispatch]);
  return (
    <>
      {show && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-lg font-semibold text-gray-900">
                        Edit Student Enrollment
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          // stroke="currentColor"
                          className="size-8 rounded-md stroke-red-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                  {/* <Enroll /> */}
                  <div className="mx-auto max-w-lg">
                    <form className="mt-6" onSubmit={handleSubmit}>
                      <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                        <div className="col-span-full">
                          <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                            <h1 className="text-lg tracking-wide text-center font-medium">
                              Editing Course Enrollment for{" "}
                              {enrollment.full_name}
                            </h1>
                          </div>
                        </div>
                        <div className="col-span-full">
                          {showAlert && (
                            <Alert
                              show={showAlert}
                              content={alertMsg}
                              type={status}
                            ></Alert>
                          )}
                        </div>
                        {/* <div className="col-span-full">
                          <p className="block text-xl font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md px-3 py-2">
                            Course Enrollment
                          </p>
                        </div> */}

                        <div className="col-span-full">
                          <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                            <label
                              htmlFor="select-course"
                              className="block text font-medium text-gray-700"
                            >
                              Select Course
                            </label>
                            <div className="mt-1">
                              <select
                                id="select-course"
                                name="course"
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                // defaultValue="Select Course"
                                onChange={handleCourseInput}
                              >
                                <option value="">Select Course</option>
                                {courses.map((course, index) => (
                                  <option key={index} value={course.id}>
                                    {course.course_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        {showClasses && (
                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="session"
                                className="block text font-medium text-gray-700"
                              >
                                Select Class
                              </label>
                              <div className="mt-1">
                                <select
                                  id="class_"
                                  name="class"
                                  placeholder="Select Class"
                                  onChange={handleClassInputChange}
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="">Select Class</option>
                                  {classes.map((class_, index) => (
                                    <option key={index} value={class_.id}>
                                      {class_.class_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                        {showSessions && (
                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <fieldset>
                                <legend className="block text font-medium text-gray-700">
                                  Select Class Sessions
                                </legend>
                                {sessions.length !== 0 ? (
                                  <div className="mt-3 space-y-2">
                                    {sessions.map((session, index) => (
                                      <div
                                        className="flex items-center gap-x-3"
                                        key={index}
                                      >
                                        <input
                                          id="session"
                                          name="session"
                                          type="checkbox"
                                          onChange={() =>
                                            handleSessionInputChange(index)
                                          }
                                          value={session.id}
                                          checked={setSessionChecked[index]}
                                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        ></input>
                                        <label
                                          htmlFor={"session-" + index}
                                          className="block text-sm font-medium leading-6 text-gray-700"
                                        >
                                          {session.day}, Time:{" "}
                                          {session.start_at} -{session.end_at}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="mt-3 space-y-2">
                                    <div className="flex items-center gap-x-3">
                                      <p>
                                        ------ Class has no sessions yet ------
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </fieldset>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateEnrollment;
