import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api/api";

import {
  fetchCourses,
  selectCourses,
  selectCourseStatus,
} from "../../features/courses/courseSlice";
import { enrollStudent } from "../../features/enrollment/enrollSlice";
import { fetchRegistrations } from "../../features/registrations/registrationSlice";
import Alert from "../../components/common/alert";

export const Enroll = () => {
  const [student, setStudent] = useState({});
  const [sessionChecked, setSessionChecked] = useState([]);
  const [enrollmentData, setEnrollmentData] = useState({});
  const [showClasses, setShowClasses] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  // const [sessionsInput, setSessionsInput] = useState([]);
  const [courseItem, setCourseItem] = useState({});
  const [classItem, setClassItem] = useState();
  const [payMethod, setPayMethod] = useState("");
  const [status, setStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParam, setSearchParamParam] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // const newStudent = useSelector(selectStudent);
  const courseStatus = useSelector(selectCourseStatus);
  // const enrollStatus = useSelector(selectEnrollStatus);
  const courses = useSelector(selectCourses);
  const { id } = location.state;

  const handleCourseInput = (e) => {
    setShowSessions(false);
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );
    if (course && course.classes.length !== 0) {
      setCourseItem(course);
      setClasses(course.classes);
      setShowClasses(true);
      setEnrollmentData({
        ...enrollmentData,
        amount:
          course.course_price !== "None"
            ? parseFloat(course.course_price)
            : 1.0,
      });
    }
  };

  const handleSessionInputChange = (position) => {
    const updatedCheckedState = sessionChecked.map((item, index) =>
      index === position ? !item : item
    );
    setSessionChecked(updatedCheckedState);
    const sessionsList = updatedCheckedState.reduce(
      (ids, currentState, index) => {
        if (currentState === true) {
          return [...ids, sessions[index].id];
        }
        return ids;
      },
      []
    );
    setEnrollmentData({ ...enrollmentData, sessions: sessionsList });
  };

  const handleClassInputChange = (e) => {
    console.log("course", courseItem);
    const class_ = courseItem.classes.find(
      (item) => item.id === parseInt(e.target.value)
    );
    if (class_) {
      setClassItem(class_);
      setSessions(class_.sessions);
      setSessionChecked(new Array(class_.sessions.length).fill(false));
      setShowSessions(true);
      setEnrollmentData({
        ...enrollmentData,
        class_id: parseInt(e.target.value),
      });
    } else {
      setShowSessions(false);
    }
  };

  const handleSearchInputChange = (e) => setSearchParamParam(e.target.value);
  const search = async (param) => {
    try {
      setShowLoading(true);
      const response = await Apis.getRegistration(param.replace(" ", ""));
      if (response.status === 200) {
        setShowLoading(false);
        const searchResults = response.data.data;
        setStudent(searchResults);
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowLoading(false);
      console.log("error", error);
      setAlertMsg(error.message);
      setShowError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Status", enrollStatus)
    try {
      const enrollInfo = {
        ...enrollmentData,
        student_id: student.id,
      };
      await dispatch(enrollStudent(enrollInfo))
        .unwrap()
        .then((results) => {
          // console.log("Enrollment Resp", results);
          const { data, msg, status } = results;
          setAlertMsg(msg);
          if (status !== 200) {
            setShowClasses(false);
            setShowSessions(false);
            setStatus("failed");
            setShowAlert(true);
          } else {
            // const enrollResp = {
            //   enrollment_id: data.enrollment_id,
            //   student_msisdn: data.student_msisdn,
            //   class: data.class,
            //   course: data.course,
            // };
            setShowAlert(false);
            setShowLoading(false);
            navigate("/mpesa-express", {
              state: { enrollment_id: data.enrollment_id },
            });
          }
        });
    } catch (error) {
      setAlertMsg(
        "Oops! That didn't work. Please Provide all enrollment information and try again."
      );
      setShowAlert(true);
    } finally {
      e.target.reset();
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchParam) {
      search(searchParam);
      setShowLoading(true);
    } else {
      setShowError(true);
    }
  };

  const fetchStudent = useCallback(async () => {
    await dispatch(fetchRegistrations({ params: { id: id } }))
      .unwrap()
      .then((results) => {
        setShowLoading(false);
        setStudent(results);
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (courseStatus === "idle") {
      dispatch(fetchCourses());
    }
    // const storedStudent = loadItem("student");

    if (Object.keys(student || {}).length === 0) {
      fetchStudent();
    }
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
      if (showError) {
        setShowError(false);
      }
      if (showLoading) {
        setShowLoading(false);
      }
    }, 5000);
  }, [
    courseStatus,
    dispatch,
    fetchStudent,
    showAlert,
    showError,
    showLoading,
    student,
  ]);

  return (
    <>
      <div className="px-4 py-6 sm:px-6 lg:hidden">
        <div className="mx-auto flex max-w-lg">
          <a href="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/iyflogo-s7.png"
              alt=""
              className="h-12 w-auto"
            ></img>
          </a>
        </div>
      </div>
      <h1 className="sr-only">IYF Free Academy - Course Enrollment</h1>
      {/* <!-- Checkout form --> */}

      {showLoading && !student ? (
        <div className="mx-auto max-w-lg">
          <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
              Enrollment
            </h5>
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Processing your request ...</span>
            </div>
          </div>
        </div>
      ) : Object.keys(student || {}).length !== 0 ? (
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg">
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <h1 className="text-2xl font-bold">
                      Congratulations, {student.full_name}!
                    </h1>
                    <p className="mt-5">
                      You have successfully registered for the Weekend Academy.
                      Time to Enroll for a Course
                    </p>
                  </div>
                </div>
                <div className="col-span-full">
                  {showAlert && (
                    <Alert
                      show={showAlert}
                      content={alertMsg}
                      type={status}
                    ></Alert>
                  )}
                </div>
                <div className="col-span-full">
                  <p className="block text-xl font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md px-3 py-2">
                    Course Enrollment
                  </p>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="select-course"
                      className="block text font-medium text-gray-700"
                    >
                      Select Course
                    </label>
                    <div className="mt-1">
                      <select
                        id="select-course"
                        name="course"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        // defaultValue="Select Course"
                        onChange={handleCourseInput}
                      >
                        <option value="">Select Course</option>
                        {courses.map((course, index) => (
                          <option key={index} value={course.id}>
                            {course.course_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {showClasses && (
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="session"
                        className="block text font-medium text-gray-700"
                      >
                        Select Class
                      </label>
                      <div className="mt-1">
                        <select
                          id="class_"
                          name="class"
                          placeholder="Select Class"
                          onChange={handleClassInputChange}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select Class</option>
                          {classes.map((class_, index) => (
                            <option key={index} value={class_.id}>
                              {class_.class_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {showSessions && (
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <fieldset>
                        <legend className="block text font-medium text-gray-700">
                          Select Class Sessions
                        </legend>
                        {sessions.length !== 0 ? (
                          <div className="mt-3 space-y-2">
                            {sessions.map((session, index) => (
                              <div
                                className="flex items-center gap-x-3"
                                key={index}
                              >
                                <input
                                  id="session"
                                  name="session"
                                  type="checkbox"
                                  onChange={() =>
                                    handleSessionInputChange(index)
                                  }
                                  value={session.id}
                                  checked={setSessionChecked[index]}
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                ></input>
                                <label
                                  htmlFor={"session-" + index}
                                  className="block text-sm font-medium leading-6 text-gray-700"
                                >
                                  {session.day}, Time: {session.start_at} -
                                  {session.end_at}
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="mt-3 space-y-2">
                            <div className="flex items-center gap-x-3">
                              <p>------ Class has no sessions yet ------</p>
                            </div>
                          </div>
                        )}
                      </fieldset>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  type="submit"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                >
                  Proceed to Payment
                </button>
                <Link
                  to="/"
                  className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </section>
      ) : (
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg">
            <div className="hidden pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy</span>
                <img
                  src="../assets/png/iyflogo-s7.png"
                  alt=""
                  className="h-20 w-auto"
                ></img>
              </a>
            </div>
            <form className="mt-6" onSubmit={handleSearchSubmit}>
              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <h1 className="text-2xl font-bold">Enroll</h1>
                    <p>
                      Enter the phone number, Email; Address or ID Number you
                      used to register to search for your registration data
                    </p>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="phone-email-id"
                      className="block text font-medium text-gray-700"
                    >
                      Enter Phone, Email or ID Number
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="fullname"
                        id="phone-email-id"
                        placeholder="Phone, email or ID"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        onChange={handleSearchInputChange}
                      ></input>

                      {/* <!-- Error message --> */}
                      {showError && (
                        <p
                          id="error-message"
                          className="mt-2 text-sm font-medium text-red-600"
                        >
                          Record not found. Please check your input or{" "}
                          <Link className="text-blue-600" to="/register">
                            register here
                          </Link>
                          .
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <button
                  type="submit"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Search
                </button>
                <Link
                  to="/"
                  className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Cancel
                </Link>
              </div>

              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <svg
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                We adhere to the highest standards of data security in all
                processing and storage of your information.
              </p>
            </form>
          </div>
        </section>
      )}
    </>
  );
};
