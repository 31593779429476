import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchCashiers = createAsyncThunk(
  "/registrations/fetchCashiers",
  async () => {
    const { data: response } = await Apis.getCashiers();
    // console.log("data", response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchCashier = createAsyncThunk(
  "/cashiers/fetchCashier",
  async (param) => {
    const { data: response } = await Apis.getCashier(param);
    // console.log(response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const addCashierAsync = createAsyncThunk(
  "cashiers/addCashier",
  async (cashier) => {
   try {
     const { status, data } = await Apis.addCashier(cashier);
     //   console.log("res success", response);
     if (status !== 201 ) {
       return { data: null, msg: data.msg, status: status };
     }
     
     return { data: data.data, msg: data.msg, status: 200 };
   } catch (error) {
    console.log("Error", error)
     const { data: response } = error.response;
     const { status } = error;
     return { data: null, msg: response.msg, status: status };
   }
  }
);

// export const selectCashierById = (state, cashierId) => {
//   return state.cashiers.cashiers.find((cashier) => cashier.id === cashierId);
// }

const initialState = {
  status: "idle",
  cashiers: [],
  error: null,
  cashierObject: {},
};

const cashierSlice = createSlice({
  name: "cashiers",
  initialState,
  reducers: {
    addCashier: (state, action) => {
      const cashiers = state.cashiers;
      const newCashiers = [...cashiers, action.payload];
      return newCashiers;
    },
    getCashierById: (state, action) => {
      return state.cashiers.find((cashier) => cashier.id === action.payload);
    },
  },
  selectors: {
    selectCashierStatus: (state) => state.status,
selectCashiersError: (state) => state.error,
selectCashiers: (state) => state.cashiers,
selectCashierById: (state, id) =>
  state.cashiers.find((cashier) => cashier.id === id)
  },
  extraReducers(builder) {
    builder
      .addCase(addCashierAsync.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.cashiers.push(data);
        }
      })
      .addCase(addCashierAsync.pending, (state, action) => {
        state.status = "pending";
        // state.cashiers.push(action.payload);
      })
      .addCase(addCashierAsync.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchCashier.fulfilled, (state, action) => {
        state.status = "success";
        state.cashierItem = action.payload;
      })
      .addCase(fetchCashier.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCashier.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchCashiers.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.status = "success";
        state.cashiers = action.payload;
      })
      .addCase(fetchCashiers.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCashiers.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { addCashier, getCashierById } =
  cashierSlice.actions;

export const {selectCashierStatus, selectCashiersError,selectCashiers,selectCashierById} = cashierSlice.selectors;

export default cashierSlice.reducer;
