import axios from 'axios';
import { getOidToken } from '../../helpers/authHelpers';

const BASE_URL = "https://register.iyfkenya.org/api/v1";
// const BASE_URL = "https://5b85-102-219-208-127.ngrok-free.app/api/v1";

export const defaultParams = () => ({
  headers: { Authorization: `Bearer ${getOidToken()}` },
});

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": BASE_URL,
  },
});
// export default axiosInstance;
