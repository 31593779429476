import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchAttendance = createAsyncThunk(
  "/attendance-registers/fetchAttendance",
  async (params) => {
    try {
      const { data: response } = await Apis.fetchAttendance(params);
    // console.log(response.data);
    if (response.data) {
      return {data: response.data, msg: response.msg, status: 200};
    } else {
      return { data: null, msg: response.msg, status: 400 };
    }
    } catch (error) {
      const { data: response } = error.response;
      return { data: null, msg: response.msg, status: error.status };
    }
  }
);

export const addRegisterAsync = createAsyncThunk(
  "attendance-registers/addRegister",
  async (register) => {
    try {
      const { status, data } = await Apis.createRegister(register);
      //   console.log("res success", response);
      if (status !== 201) {
        return { data: null, msg: data.msg, status: status };
      }

      return { data: data.data, msg: data.msg, status: 200 };
    } catch (error) {
    //   console.log("Error", error);
      console.log("action.payload", error);
      const { data: response } = error.response;
    //   const { status } = error;
      return { data: null, msg: response.msg, status: 500 };
    }
  }
);
export const updateRegister = createAsyncThunk(
  "attendance-registers/updateRegister",
  async (register) => {
    try {
      const { status, data } = await Apis.createRegister(register);
      //   console.log("res success", response);
      if (status !== 201) {
        return { data: null, msg: data.msg, status: status };
      }

      return { data: data.data, msg: data.msg, status: 200 };
    } catch (error) {
    //   console.log("Error", error);
      console.log("action.payload", error);
      const { data: response } = error.response;
    //   const { status } = error;
      return { data: null, msg: response.msg, status: 500 };
    }
  }
);

// export const selectRegisterById = (state, registerId) => {
//   return state.attendance-registers.attendance-registers.find((register) => register.id === registerId);
// }

const initialState = {
  status: "idle",
  attendance: [],
  error: null,
  registerObject: {},
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    addRegister: (state, action) => {
      const attendance = state.attendance;
      const newRegisters = [...attendance, action.payload];
      return newRegisters;
    },
    getRegisterById: (state, action) => {
      return state.attendance.find((register) => register.id === action.payload);
    },
  },
  selectors: {
    selectRegisterStatus: (state) => state.status,
    selectRegistersError: (state) => state.error,
    selectRegisters: (state) => state.attendance,
    selectRegisterById: (state, id) =>
      state.attendance.find((register) => register.id === id),
  },
  extraReducers(builder) {
    builder
      .addCase(addRegisterAsync.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.attendance.push(data);
        }
      })
      .addCase(addRegisterAsync.pending, (state, action) => {
        state.status = "pending";
        // state.attendance.push(action.payload);
      })
      .addCase(addRegisterAsync.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateRegister.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.attendance.push(data);
        }

        // state.error = msg;
        // if (status !== 200) {
        //   state.status = "failed";
        // } else {
        //   state.status = "success";
        //   state.enrollments = state.enrollments.map((enrollment) => {
        //     if (enrollment.id === data.id) {
        //       const updated = { ...enrollment, ...data };
        //       console.log("updates::::", updated);
        //       return updated;
        //     } else {
        //       return enrollment;
        //     }
        //   });
        // }
      })
      .addCase(updateRegister.pending, (state, action) => {
        state.status = "pending";
        // state.attendance.push(action.payload);
      })
      .addCase(updateRegister.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.status = "success";
        const {data, msg, status} = action.payload;
        if (Array.isArray(data)) {
          state.attendance = data
        }else{
          state.registerItem = action.payload;
        }
      })
      .addCase(fetchAttendance.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchAttendance.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { addRegister, getRegisterById } = attendanceSlice.actions;

export const {
  selectRegisterStatus,
  selectRegistersError,
  selectRegisters,
  selectRegisterById,
} = attendanceSlice.selectors;

export default attendanceSlice.reducer;
