import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";
import { saveItem } from "../../utils/helpers/localStorage";

// export const fetchRegistrations = createAsyncThunk(
//   "/registrations/fetchRegistrations",
//   async () => {
//     const { data: response } = await Apis.getRegistration();
//     console.log(response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const getStudent = createAsyncThunk(
  "/registrations/getStudent",
  async (param) => {
    const { data: response } = await Apis.fet(param);
    // console.log("Reg Found >>>", response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchRegistrations = createAsyncThunk(
  "/registrations/fetchAll",
  async (param) => {
    const { data: response } = await Apis.getRegistrations(param);
    // console.log(response.data);
    if (response.data) {
      return response.data;
    } else {
      return null;
    }
  }
);

export const registerStudent = createAsyncThunk(
  "students/registerStudent",
  async (student) => {
    try {
      const { data: response } = await Apis.registerStudent(student);
      console.log("Response", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      console.log("newStudent", response.data);
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { msg: error_msg } = error.response.data;
      console.log("Register student Error", error.response.data);
      return { data: null, msg: error_msg };
    }
  }
);

export const updateRegistration = createAsyncThunk(
  "students/updateRegistration",
  async (student) => {
    try {
      const { data: response } = await Apis.updateStudent(student);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { msg: error_msg } = error.response.data;
      return { data: null, msg: error_msg, status: 500 };
    }
  }
);

const initialState = {
  students: [],
  status: "idle",
  msg: null,
  getResults: {},
  student: {},
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    // registerStudent: (state, action) => {
    //   const registrations = state.students;
    //   const newRegistrations = [...registrations, action.payload];

    //   return {
    //     ...state.students,
    //     newRegistrations,
    //   };
    // },
    getStudent: (state) => state.student,
    getStatus: (state) => state.status,
    setSearchResult: (state, action) => {
      return { ...state, getResults: action.payload };
    },
    setNewStudent: (state, action) => {
      return { ...state, newStudent: action.payload };
    },
  },
  selectors: {
    selectRegistrationState: (state) => state.status,
    selectStudent: (state) => state.student,
    selectGetResults: (state) => state.getResults,
    selectAllStudents: (state) => state.students,
    selectStudentById: (state, id) =>
      state.students.find((student) => student.id === id),
    selectErrorMsg: (state) => state.msg,
  },
  extraReducers(builder) {
    builder
      .addCase(registerStudent.fulfilled, (state, action) => {
        state.status = "success";
        const { data, msg, status } = action.payload;
        if (status !== 200) {
          state.status = "success";
          state.msg = msg;
        } else {
          state.student = data;
          console.log("Register student", state.student);
          state.students.push(data);
        }
      })
      .addCase(registerStudent.pending, (state, action) => {
        state.status = "pending";
        // state.registration.push(action.payload);
      })
      .addCase(registerStudent.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload.msg;
      })
      .addCase(updateRegistration.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        if (status !== 200) {
          state.status = "failed";
          state.msg = msg;
        } else {
          // state.student = data;
          state.status = "success";
          console.log("data", data);
          state.students = state.students.map((student) => {
            if (student.id === data.id) {
              const updated = { ...student, ...data };
              console.log("updated Student::::", updated);
              return updated;
            } else {
              return student;
            }
          });
        }
      })
      .addCase(updateRegistration.pending, (state, action) => {
        state.status = "pending";
        // state.registration.push(action.payload);
      })
      .addCase(updateRegistration.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload.msg;
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.status = "success";
        state.getResults = action.payload;
      })
      .addCase(getStudent.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getStudent.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchRegistrations.fulfilled, (state, action) => {
        state.status = "success";
        if (Array.isArray(action.payload)) {
          state.students = action.payload;
        } else {
          state.student = action.payload;
        }
      })
      .addCase(fetchRegistrations.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchRegistrations.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});
export const { setSearchResult, setNewStudent, getNewStudent, getStatus } =
  registrationSlice.actions;
export const {
  selectStudent,
  selectRegistrationState,
  selectGetResults,
  selectAllStudents,
  selectErrorMsg,
  selectStudentById,
} = registrationSlice.selectors;
export default registrationSlice.reducer;
