import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Registrations } from "./registrations";
import { fetchMpesaClassicRequests, fetchPaymentRequests, fetchSTKRequests, fetchTransactionQueryRequests, fetchTransactions, selectPaymentStatus } from "../../features/payments/paymentSlice";
import { fetchEnrollments, selectEnrollStatus } from "../../features/enrollment/enrollSlice";
import { fetchRegistrations, selectRegistrationState } from "../../features/registrations/registrationSlice";

export const Admin = () => {
    const paymentRequestStatus = useSelector(selectPaymentStatus);
    const enrollmentStatus = useSelector(selectEnrollStatus);
    const registrationStatus = useSelector(selectRegistrationState);
    const dispatch = useDispatch();

    useEffect(() => {
      if (paymentRequestStatus === "idle") {
        dispatch(fetchPaymentRequests());
        dispatch(fetchSTKRequests())
        dispatch(fetchTransactions());
        dispatch(fetchMpesaClassicRequests());
        dispatch(fetchTransactionQueryRequests());
      }
      if (enrollmentStatus === "idle") {
        dispatch(fetchEnrollments());
      }
      if (registrationStatus === "idle") {
        dispatch(fetchRegistrations());
      }
    }, [dispatch, enrollmentStatus, paymentRequestStatus, registrationStatus]);
  return (
    <>
        <Registrations/>
    </>
  )
}