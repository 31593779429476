import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCashier,
  fetchCashiers,
  selectCashiers,
  selectCashiersError,
  selectCashierStatus,
} from "../../features/cashiers/cashierSlice";
import { Link } from "react-router-dom";
import Modal from "../../features/cashiers/AddModal";

export const Cashiers = () => {
  const [state, setState] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [cashier, setCashier] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const status = useSelector(selectCashierStatus);
  const err = useSelector(selectCashiersError);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCashier({ ...cashier, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addCashier(cashier)).unwrap();
    } catch (error) {
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: "Course Added Successfully",
      });
      setShowAlert(true);
      e.target.reset();
      setCashier({});
    }
  };

  const cashierStatus = useSelector(selectCashierStatus);

  const cashiers = useSelector(selectCashiers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cashierStatus === "idle") {
      dispatch(fetchCashiers());
    }
  }, [cashierStatus, dispatch]);
  return (
    <>
      {showModal && (
        <Modal show={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="flex items-start justify-end w-full">
            <button
              data-modal-target="default-modal"
              data-modal-toggle="default-modal"
              className="rounded-md border border-transparent bg-sky-950 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add New
            </button>
          </div>
          {cashiers && cashiers.length !== 0 ? (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Phone Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Date Added
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {cashiers.map((item, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.first_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.last_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.user.msisdn}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.user.email}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.status}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.created_at}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <Link
                              to="/dashboard/cashier-details"
                              state={{ id: item.id }}
                              className="mx-2 rounded-md border border-transparent bg-blue-700 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                              // onClick={() => {}}
                            >
                              View
                            </Link>
                            <Link
                              to="/dashboard/edit-cashier"
                              state={{ id: item.id }}
                              className="mx-2 rounded-md border border-transparent bg-blue-700 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                              onClick={() => {}}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <p>No Data</p>
          )}
        </div>
      </div>
    </>
  );
};
