import { get, post, put, patch, deleteRequest } from "./base";

// const BASE_URL = "http://127.0.0.1:5000/api/v1";
// const BASE_URL = "https://5b85-102-219-208-127.ngrok-free.app/api/v1";

const Apis = {
  login: (user_data) => post("/auth/login", user_data),
  registerStudent: (data) => post("/students/register-student", data),
  updateStudent: (data) => patch("/students/update-student", data),
  enroll: (data) => post("/students/enroll", data),
  verifyEnrollment: (data) => post("/students/verify-enrollment", data),
  updateEnrollment: (data) => patch("/students/update-enrollment", data),
  deleteEnrollment: (param) => deleteRequest("/students/delete-enrollment", param),
  getRegistrations: (params) =>
    get("/students", params, { validateStatus: false }),
  // fetchStudent: (param) =>
  //   get("/students?id=" + param, { validateStatus: false }),
  // fetchAllStudents: (params) =>
  //   get("/students", params, { validateStatus: false }),
  getCourses: () => get("/courses"),
  getCourse: (id) => get("/courses?q=" + id),
  addCourse: (course) => post("/courses/add-course", course),
  updateCourse: (course_id) => patch("/courses/update-course" + course_id),
  deleteCourse: (course_id) =>
    deleteRequest("/courses/update-course" + course_id),

  // getSessions: (course_id) =>
  //   get("/sessions?course_id=" + course_id),
  getSessions: (params) => get("/sessions", params),
  addSessions: (sessions) => post("/sessions/add-sessions", sessions),
  assignTutorSession: (data) => post("/sessions/assign-tutor", data),
  updateSession: (data) => patch("/sessions/update-session", data),
  deleteSession: (session_id) =>
    deleteRequest("/sessions/update-session" + session_id),

  getTutors: (params) => get("/tutors", params),
  getTutor: (id) => get("/tutors?q" + id),
  addTutor: (tutor) => post("/tutors/create-tutor", tutor),
  updateTutor: (tutor_id) => put("/tutors/update-tutor" + tutor_id),

  getCashiers: () => get("/cashiers"),
  getCashier: (id) => get("/cashiers?" + id),
  addCashier: (cashier) => post("/cashiers/add-cashier", cashier),
  updateCashier: (cashier_id) => put("/cashiers/update-cashier" + cashier_id),

  // attendance registers
  createRegister: (data) => post("/attendance-registers/add-register", data),
  fetchAttendance: (params) => get("/attendance-registers", params),
  updateRegister: (data) => post("/attendance-registers/update-register", data),

  // payments
  makePayment: (data) => post("/payments/pay", data),
  getTransactions: (params) => get("/payments/transactions", params),
  getSTKPushRequests: (params) => get("/payments/express-requests", params),
  getMpesaClassicRequests: (params) =>
    get("/payments/mpesa-classic-requests", params),
  getPaymentRequests: (params) => get("/payments/payment-requests", params),
  getTransactionQueryRequests: (params) =>
    get("/payments/transaction-query-requests", params),
  getEnrollments: (params) => get("/students/enrollments", params),
};

export default Apis;
