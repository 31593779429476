import { axiosInstance, defaultParams } from "./axios";

const deleteRequest = async (url, body, params) => {
  try {
    return await axiosInstance.delete(url, body, { ...defaultParams(), ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    localStorage.setItem(
      "url",
      `${window.location.pathname}${window.location.search}`
    );
    try {
      return axiosInstance.delete(url, body, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};

export default deleteRequest;
