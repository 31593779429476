import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 lg:px-6 mb-20">
        <div className="flex flex-col items-center justify-center mx-auto max-w-2xl lg:max-w-none">
          <div className="flex items-center justify-center mx-auto mb-8 mt-8 lg:mt-8">
            <a href="/">
              <span className="sr-only">IYF Free Weekend Academy</span>
              <img
                src="../assets/png/iyflogo-s7.png"
                alt=""
                className="h-12 lg:h-16 w-auto"
              ></img>
            </a>
          </div>

          <dl className="grid grid-cols-1 gap-8 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-2 max-w-2xl">
            <div className="flex flex-col bg-white p-4 shadow-sm border border-gray-200 rounded-xl">
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center justify-center w-full pt-2">
                  <img
                    src="../assets/png/register.png"
                    alt=""
                    className="h-24"
                  ></img>
                </div>
                <div className="mt-4">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href="/">Register for Academy</a>
                  </h3>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    If you're new to our weekend academy, please fill out the
                    Registration form with your basic information.
                  </p>
                  <Link
                    to="/register"
                    className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                  >
                    Register
                  </Link>
                </div>
              </article>
            </div>
            <div className="flex flex-col bg-white p-4 shadow-sm border border-gray-200 rounded-xl">
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center justify-center w-full pt-2">
                  <img
                    src="../assets/png/enroll.png"
                    alt=""
                    className="h-24"
                  ></img>
                </div>
                <div className="mt-4">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href="/">Enroll and Pay</a>
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-600">
                    Confirm the class you wish to join. Please note that a
                    payment of 500 Shillings is required to complete your
                    enrollment.
                  </p>
                  <Link
                    to="#"
                    style={{cursor: "default"}}
                    className="mt-6 rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                  >
                    Enroll
                  </Link>
                </div>
              </article>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
