import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchSessions = createAsyncThunk(
  "/sessions/fetchSessions",
  async (params) => {
    try {
      const { data: { data, msg, success } } = await Apis.getSessions(params);
    console.log("data", data, success);
    if (!success) {
      return { data: null, msg: msg, status: 400 };
    }
    return { data: data, msg: msg, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      // console.log("Error:", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);

// export const fetchSession = createAsyncThunk(
//   "/sessions/fetchSession",
//   async (param) => {
//     const { data: response } = await Apis.getSession(param);
//     // console.log(response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const addSession = createAsyncThunk(
  "sessions/addSession",
  async (sessions) => {
    // try {
    //   const { data: response } = await Apis.addSessions(sessions);
    //   // console.log("res success", response);
    //   if (!response.success) {
    //     return null;
    //   }
    //   return response.data;
    // } catch (error) {
    //   console.log("Error:", error);
    //   return null;
    // }

    try {
      const { data: response } = await Apis.addSessions(sessions);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      // console.log("Error:", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);

export const updateSession = createAsyncThunk(
  "sessions/updateSession",
  async (data) => {
    try {
      const { data: response } = await Apis.updateSession(data);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      // console.log("Error:", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);
export const assignTutor = createAsyncThunk(
  "sessions/assignTutor",
  async (data) => {
    try {
      const { data: response } = await Apis.assignTutorSession(data);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      // console.log("Error:", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);

const initialState = {
  status: "idle",
  sessions: [],
  error: null,
  session: {},
};

const sessionSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    addSession: (state, action) => {
      const sessions = state.sessions;
      const newSessions = [...sessions, action.payload];
      return newSessions;
    },
  },
  selectors: {
    selectSessionsStatus: (state) => state.status,
    selectSessionsError: (state) => state.error,
    selectSessions: (state) => state.sessions,
    selectSessionByClass: (state, class_id) =>
      state.sessions.find((session) => session.class_id === class_id),
    selectSessionById: (state, session_id) =>
      state.sessions.find((session) => session.id === session_id),
  },
  extraReducers(builder) {
    builder
      .addCase(addSession.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.sessions.push(data);
        }
      })
      .addCase(addSession.pending, (state, action) => {
        state.status = "pending";
        state.sessions.push(action.payload);
      })
      .addCase(addSession.rejected, (state, action) => {
        state.status = "failed";
      })
      // .addCase(fetchSession.fulfilled, (state, action) => {
      //   state.status = "success";
      //   state.session = action.payload;
      // })
      // .addCase(fetchSession.pending, (state, action) => {
      //   state.status = "pending";
      // })
      // .addCase(fetchSession.rejected, (state, action) => {
      //   state.status = "failed";
      // })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.status = "success";
        state.error = msg
        if (Array.isArray(data)) {
          state.sessions = data;
        }else{
          state.session = data
        }
      })
      .addCase(fetchSessions.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(assignTutor.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.status = "success";
        const sessionItem = state.sessions.find(
          (session) => session.id === data.id
        );
        sessionItem.tutor = data.tutor;
        state.error = msg;
        // console.log(sessionItem);
        // state.sessions = action.payload;
      })
      .addCase(assignTutor.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(assignTutor.rejected, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        state.status = "failed";
      });
  },
});

export const {
  selectSessions,
  selectSessionByClass,
  selectSessionById,
  selectSessionsStatus,
  selectSessionsError,
} = sessionSlice.selectors;
export default sessionSlice.reducer;
