import React from "react";

const Alert = ({ content, type }) => {
  var classProps =
    "mb-4 p-3 text-base text-center text-blue-500 bg-neutral-300 rounded-lg";
  if (type === "failed") {
    classProps =
      "mb-4 p-5 text-base text-center font-normal rounded-lg  bg-red-400";
  }else if(type === "success"){
    classProps =
      "mb-4 p-5 text-base text-center font-normal rounded-lg  bg-green-500";
  }
  return (
    <>
      <div
        className={classProps}
        role="alert"
      >
        <span className="font-medium">{content}</span>
      </div>
    </>
  );
};

export default Alert;
