import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Apis from "../../utils/api/api";
import Alert from "../../components/common/alert";

import {
  fetchCourses,
  fetchCourse,
  selectCourses,
  selectCourseItem,
  selectCouseStatus,
  selectCourseStatus,
} from "../../features/courses/courseSlice";

import {
  getNewStudent,
  getStatus,
  registerStudent,
  selectErrorMsg,
  selectStudent,
  selectRegistrationState,
  setNewStudent,
} from "../../features/registrations/registrationSlice";
import { saveItem } from "../../utils/helpers/localStorage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  enrollStudent,
  verifyEnrollment,
} from "../../features/enrollment/enrollSlice";

// const courseList = [
//   {
//     course_code: "GD001",
//     course_details: "graphic design course",
//     course_name: "Graphics",
//     course_price: null,
//     id: 1,
//   },
//   {
//     course_code: "WD001",
//     course_details: "Web design course",
//     course_name: "Web Design",
//     course_price: null,
//     id: 3,
//   },
//   {
//     course_code: "GD002",
//     course_details: "design course",
//     course_name: "GraphicsDesign",
//     course_price: null,
//     id: 4,
//   },
// ];

export const Register = () => {
  const [privacyPolicyStatus, setPrivacyPolicyStatus] = useState(false);
  const [student, setStudent] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [err, setErr] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [age, setAge] = useState(0);
  const [showId, setShowId] = useState(false);
  const [dobValue, setDobValue] = useState(dayjs());
  const [dob, setDob] = useState({});
  const [enrollmentData, setEnrollmentData] = useState({});
  const [classes, setClasses] = useState([]);
  const [courseItem, setCourseItem] = useState({});
  const [showClasses, setShowClasses] = useState(false);
  const [classItem, setClassItem] = useState();
  const [status, setStatus] = useState("");
  const [alertMsg, setAlertMsg] = useState(false);
  const [verificationInfo, setVerificationInfo] = useState({});
  const [enrollmentResponse, setEnrollmentResponse] = useState({});
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const coursesList = [
    { id: 18, course_name: "Art" },
    { id: 8, course_name: "Automotive Repair" },
    { id: 6, course_name: "Camera Operation" },
    { id: 22, course_name: "Carpentry" },
    { id: 25, course_name: "Catering" },
    { id: 13, course_name: "Chinese" },
    { id: 3, course_name: "Computer Packages" },
    { id: 27, course_name: "Cosmetology" },
    { id: 17, course_name: "Dance" },
    { id: 20, course_name: "Digital Marketing" },
    { id: 5, course_name: "Electrical Installation" },
    { id: 4, course_name: "Fashion & Design" },
    { id: 16, course_name: "Football" },
    { id: 12, course_name: "French " },
    { id: 1, course_name: "Graphics & Design" },
    { id: 14, course_name: "Korean " },
    { id: 15, course_name: "Mind Education" },
    { id: 28, course_name: "Music  " },
    { id: 23, course_name: "Networking & CCTV " },
    { id: 21, course_name: "News & TV Production" },
    { id: 2, course_name: "Paramedics" },
    { id: 24, course_name: "Plumbing" },
    { id: 9, course_name: "Programming  " },
    { id: 11, course_name: "Sign Language " },
    { id: 19, course_name: "Taekwondo " },
    { id: 26, course_name: "Theology " },
    { id: 10, course_name: "UX Design  " },
    { id: 7, course_name: "Video Editing" },
  ];

  const classList = [
    { id: 1, course_id: 1, class_name: "Graphics & Design" },
    { id: 4, course_id: 2, class_name: "Paramedics" },
    { id: 6, course_id: 3, class_name: "Computer Packages" },
    { id: 8, course_id: 4, class_name: "Fashion & Design" },
    { id: 10, course_id: 5, class_name: "Electrical Installation" },
    { id: 12, course_id: 6, class_name: "Camera Operation" },
    { id: 14, course_id: 7, class_name: "Video Editing" },
    { id: 16, course_id: 8, class_name: "Automotive Repair" },
    { id: 18, course_id: 9, class_name: "Programming" },
    { id: 20, course_id: 10, class_name: "UX Design" },
    { id: 22, course_id: 11, class_name: "Sign Language" },
    { id: 24, course_id: 12, class_name: "French" },
    { id: 26, course_id: 13, class_name: "Chinese" },
    { id: 28, course_id: 14, class_name: "Korean" },
    { id: 30, course_id: 15, class_name: "Mind Education" },
    { id: 32, course_id: 16, class_name: "Football" },
    { id: 34, course_id: 17, class_name: "Dance" },
    { id: 36, course_id: 18, class_name: "Art" },
    { id: 38, course_id: 19, class_name: "Taekwondo" },
    { id: 40, course_id: 20, class_name: "Digital Marketing" },
    { id: 42, course_id: 21, class_name: "News & TV Production   " },
    { id: 44, course_id: 22, class_name: "Carpentry" },
    { id: 46, course_id: 23, class_name: "Networking & CCTV" },
    { id: 48, course_id: 24, class_name: "Plumbing" },
    { id: 50, course_id: 25, class_name: "Catering" },
    { id: 52, course_id: 26, class_name: "Theology" },
    { id: 55, course_id: 27, class_name: "Beauty" },
    { id: 56, course_id: 27, class_name: "Hairdressing" },
    { id: 57, course_id: 27, class_name: "Barbering" },
    { id: 59, course_id: 28, class_name: "Keyboard" },
    { id: 60, course_id: 28, class_name: "Guitar" },
    { id: 61, course_id: 28, class_name: "Drums" },
    { id: 62, course_id: 28, class_name: "Vocals" },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const courseStatus = useSelector(selectCourseStatus);
  // const enrollStatus = useSelector(selectEnrollStatus);
  const courses = useSelector(selectCourses);
  const registrationStatus = useSelector(selectRegistrationState);
  const regErrorMsg = useSelector(selectErrorMsg);
  const added = useSelector(selectStudent);

  const handleCourseInput = (e) => {
    // setShowSessions(false);
    const course = coursesList.find(
      (course) => course.id === parseInt(e.target.value)
    );
    if (course && classList.length !== 0) {
      setCourseItem(course);
      setClasses(classList.filter(cls => cls.course_id === course.id));
      setShowClasses(true);
      setEnrollmentData({
        ...enrollmentData,
        amount:
          course.course_price !== "None"
            ? parseFloat(course.course_price)
            : 1.0,
      });
    }
  };

  const handleClassInputChange = (e) => {
    console.log("course", courseItem);
    const class_ = classList.find(
      (item) => item.id === parseInt(e.target.value)
    );
    if (class_) {
      setClassItem(class_);
      // setSessions(class_.sessions);
      // setSessionChecked(new Array(class_.sessions.length).fill(false));
      // setShowSessions(true);
      setEnrollmentData({
        ...enrollmentData,
        class_id: parseInt(e.target.value),
      });
    } else {
      // setShowSessions(false);
    }
  };
  // const register = async (data) => {
  //   try {
  //     const apiResponse = await Apis.registerStudent(data);
  //     console.log("apiResponse>>> ", apiResponse);
  //     const { data: response } = apiResponse;
  //     //     // console.log("Response", response);
  //     // if (registrationStatus === "failed") {
  //     //   // setErr(response.msg);
  //     //   setShowAlert(true);
  //     //   setStudent({});
  //     // } else {
  //     // }
  //     await dispatch(registerStudent(data));

  //     if(regState === "success"){

  //     }
  //   if (apiResponse.status !== 201) {
  //     setErr(response.msg);
  //     setShowAlert(true);
  //     // console.log("error >>>", response.data.toString());
  //     setStudent({});
  //   } else {
  //     setErr(response.data.msg);
  //     setShowAlert(true);
  //     dispatch(setNewStudent(response.data));
  //     setAddedRecord(response.data);
  //   }
  // } catch (error) {
  //   console.log("error >>>", error);
  //   const { data: response } = error.response;
  //   // setRegState("failed");
  //   setErr(response.msg);
  //   setShowAlert(true);
  //   // setStudent({});
  // }
  // };

  const handleDOBInput = (e) => {
    // setDobValue(val);
    // console.log("Dob input", name, val?.toString());
    // var dobString = "";
    // dobString = `${val.toString()}/`;
    // if (name === "day") {
    //   setDob({ ...dob, day: val.toString() });
    // } else if (name === "month") {
    //   setDob({ ...dob, month: val + 1 });
    // } else {
    //   setDob({ ...dob, year: val?.toString() });
    //   const date = `${dob?.day}/${dob?.month}/${val?.toString()}`;
    //   console.log("date date", date);
    //   setStudent({ ...student, dob: date });
    //   console.log("date dob", new Date(val, dob?.month, dob?.day));
    //   // setDobValue(date)
    //   var dateToday = new Date();
    //   var dateA = new Date(date);

    //   const years = Math.round(
    //     (dateToday - dateA) / (60 * 60 * 24 * 365 * 1000)
    //   );
    //   console.log(years);
    //   setAge(years);
    //   setShowId(true);
    // }
    var dateToday = new Date();
    var dateA = new Date(e.target.value);

    const years = Math.round((dateToday - dateA) / (60 * 60 * 24 * 365 * 1000));
    console.log(years);
    setAge(years);
    setShowId(true);
    setStudent({ ...student, dob: e.target.value });
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setStudent({ ...student, [name]: value });
  };

  const handlePolicyInput = (e) => {
    setPrivacyPolicyStatus(!privacyPolicyStatus);
  };

  const handleCompleteEnrollment = async (e) => {
    try {
      // console.log("VerificationInfo::", verificationInfo);
      if (Object.keys(enrollmentResponse || {}).length !== 0) {
        await dispatch(verifyEnrollment(enrollmentResponse))
          .unwrap()
          .then((results) => {
            // console.log("Pay results", results);
            const { data, msg, status } = results;
            if (status !== 200) {
              navigate("/enrollment-successful", {
                state: { status: "failed", msg: msg },
              });
            } else {
              navigate("/enrollment-successful", {
                state: { status: "success", data: data, msg: msg },
              });
            }
          });
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("studentRegData", student);
      await dispatch(registerStudent(student))
        .unwrap()
        .then(async (results) => {
          const { data, msg, status } = results;

          if (status !== 200) {
            setErr(msg);
            setShowAlert(true);
          } else {
            saveItem("student", data);
            setShowAlert(false);
            setShowLoading(true);

            const enrollInfo = {
              ...enrollmentData,
              student_id: data.id,
            };
            await dispatch(enrollStudent(enrollInfo))
              .unwrap()
              .then((results) => {
                // console.log("Enrollment Resp", results);
                const { data, msg, status } = results;
                setAlertMsg(msg);
                if (status !== 200) {
                  setShowClasses(false);
                  // setShowSessions(false)
                  setStatus("failed");
                  setShowAlert(true);
                  // navigate("/mpesa-express", {state: {enrollment_id: data.enrollment_id}});
                  // navigate("/enrollment-successful", {
                  //   state: { status: "failed", msg: msg },
                  // });
                } else {
                  // const enrollResp = {
                  //   enrollment_id: data.enrollment_id,
                  //   student_msisdn: data.student_msisdn,
                  //   class: data.class,
                  //   course: data.course,
                  // };
                  // setEnrollmentResponse({
                  //   ...enrollmentResponse,
                  //   enrollment_id: data.enrollment_id,
                  //   student_msisdn: data.student_msisdn,
                  // });
                  setShowAlert(false);
                  setShowLoading(false);

                  // navigate("/enrollment-successful", {
                  //   state: { status: "success", data: data, msg: msg },
                  // });
                  setShowAlert(false);
                  setShowLoading(true);
                  navigate("/registration-confirmation", {
                    state: {
                      enrollment_id: data.enrollment_id,
                      student_msisdn: data.student_msisdn,
                    },
                  });
                  // e.target.reset();
                }
              });

            // saveItem("student", data);
            // setShowAlert(false);
            // setShowLoading(true);
            // navigate("/registration-confirmation");
          }
        });
      // dispatch(getNewStudent());
      // dispatch(getStatus());
    } catch (error) {
      setErr("Oops! That didn't work. Please try again.");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    // if (courses.length === 0) {
    //   dispatch(fetchCourses());
    // }
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 6000);
    }
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    }
  }, [added, setShowLoading, showAlert, showLoading, dispatch]);

  return (
    <>
      {/* <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden"> */}
      <div className="px-4 py-6 sm:px-6 lg:hidden">
        <div className="mx-auto flex max-w-lg">
          <Link to="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/iyflogo-s7.png"
              alt=""
              className="h-12 w-auto"
            ></img>
          </Link>
        </div>
      </div>

      <h1 className="sr-only">IYF Free Academy - Registration</h1>

      {/* <!-- Checkout form --> */}
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="pb-4 pt-12 lg:flex">
            <a href="/">
              <span className="sr-only">IYF Free Academy</span>
              <img
                src="../assets/png/iyflogo-s7.png"
                alt=""
                className="h-20 w-auto"
              ></img>
            </a>
          </div>
          {showLoading ? (
            <div className="mx-auto max-w-lg">
              <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  Registration
                </h5>
                <div
                  role="status"
                  className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Processing your request ...</span>
                </div>
              </div>
            </div>
          ) : (
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <h1 className="text-2xl font-bold">
                      Welcome to Season 7 of IYF Free Weekend Academy
                    </h1>
                    <p className="mb-2">
                      <i>
                        <b>Please register yourself once.</b>
                      </i>{" "}
                    </p>

                    <p className="mb-2">
                      <b>
                        <u>
                          Please Note the program is on a first come first
                          served basis, Courses that reach a limited number of
                          trainees are closed until the next intake! The IYF
                          Weekend Academy is a 3 Months Training Course. Season
                          7 beginning 12th Oct 2024.
                        </u>
                      </b>
                    </p>
                    <p className="mb-2">
                      The IYF Weekend Academy is a 3 Months Training Course
                    </p>
                    <p className="mb-2">
                      We will have an information session (ORIENTATION DAY) ON
                      Saturday, 12th October 2024 @9:00am and 2:00PM at the IYF
                      HEADQUARTERS, Off Thika road, Behind the Safari Park
                      Hotel, Next to USIU.
                    </p>
                    <p className="mb-2">
                      For Information Call 0708 333 444 or 0704 333 111 – follow
                      us on our Social Media Platforms; FB, IG, Tiktok & X
                      @IYFKenya.
                    </p>
                  </div>
                </div>
                <div className="col-span-full">
                  {showAlert && (
                    <Alert
                      show={showAlert}
                      content={err}
                      type={registrationStatus}
                    ></Alert>
                  )}
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="full_name"
                      className="block text font-medium text-gray-700"
                    >
                      Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="full_name"
                        id="full_name"
                        autoComplete="Full Name"
                        placeholder="Full Name"
                        onChange={(e) => handleInputChange(e)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="msisdn"
                      className="block text font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="msisdn"
                        id="msisdn"
                        autoComplete="Phone Number"
                        placeholder="Phone Number"
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="email-address"
                      className="block text font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email-address"
                        autoComplete="Email Address"
                        placeholder="Email Address"
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                </div>
                {/* <div className="col-span-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="grid grid-cols-3 gap-4">
                      <div className="p-2 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <DatePicker
                          label={'"Day"'}
                          views={["day"]}
                          name="dobDay"
                          value={dobValue}
                          onChange={(newValue) =>
                            handleDOBInput({
                              name: "day",
                              val: newValue?.toDate().getDate(),
                            })
                          }
                        />
                      </div>
                      <div className="p-2 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <DatePicker
                          label={'"Month"'}
                          views={["month"]}
                          name="dodMonth"
                          value={dobValue}
                          onChange={(newValue) =>
                            handleDOBInput({
                              name: "month",
                              val: newValue?.toDate().getMonth(),
                            })
                          }
                        />
                      </div>
                      <div className="p-2 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <DatePicker
                          disableFuture
                          label={'"Year"'}
                          views={["year"]}
                          name="dodYear"
                          value={dobValue}
                          onChange={(newValue) =>
                            handleDOBInput({
                              name: "year",
                              val: newValue?.toDate().getFullYear(),
                            })
                          }
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                </div> */}

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="dob"
                      className="block text font-medium text-gray-700"
                    >
                      Date of Birth
                    </label>
                    <div className="mt-1">
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        autoComplete="DOB"
                        placeholder="dd/mm/yyyy"
                        onChange={(e) => handleDOBInput(e)}
                        className="block border  text-gray-900 text-sm rounded-lg w-full  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                </div>
                {showId &&
                  (age > 18 ? (
                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="id-number"
                          className="block text font-medium text-gray-700"
                        >
                          ID Number
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="id_number"
                            name="id_number"
                            autoComplete="Id Number"
                            placeholder="ID Number"
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-span-full">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                          <label
                            htmlFor="guardian_name"
                            className="block text font-medium text-gray-700"
                          >
                            Name of Parent
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="guardian_name"
                              id="guardian_name"
                              autoComplete="cc-exp"
                              placeholder="Parent Full Name"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                        </div>
                        <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                          <label
                            htmlFor="guardian_id_no"
                            className="block text font-medium text-gray-700"
                          >
                            Parent or Guardian ID No.
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="guardian_id_no"
                              id="guardian_id_no"
                              autoComplete=""
                              placeholder="Parent Guardian ID Number"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-full mt-2">
                        <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                          <label
                            htmlFor="parent-phone"
                            className="block text font-medium text-gray-700"
                          >
                            Parent/Guardian Phone Number
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              id="parent-phone"
                              name="guardian_msisdn"
                              autoComplete="email"
                              placeholder="eg. 0700 123 456"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="current_residence"
                      className="block text font-medium text-gray-700"
                    >
                      Current Residence
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="residence"
                        id="current_residence"
                        placeholder="e.g Githiurai"
                        autoComplete="Current Residence"
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <p className="block text-xl font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md px-3 py-2">
                    Course Enrollment
                  </p>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="select-course"
                      className="block text font-medium text-gray-700"
                    >
                      Select Course
                    </label>
                    <div className="mt-1">
                      <select
                        id="select-course"
                        name="course"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        // defaultValue="Select Course"
                        onChange={handleCourseInput}
                      >
                        <option value="">Select Course</option>
                        {coursesList.map((course, index) => (
                          <option key={index} value={course.id}>
                            {course.course_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {showClasses && (
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="session"
                        className="block text font-medium text-gray-700"
                      >
                        Select Class
                      </label>
                      <div className="mt-1">
                        <select
                          id="class_"
                          name="class"
                          placeholder="Select Class"
                          onChange={handleClassInputChange}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select Class</option>
                          {classes.map((class_, index) => (
                            <option key={index} value={class_.id}>
                              {class_.class_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <fieldset>
                      <legend className="block text font-medium text-gray-700">
                        How did you know about weekend academy
                      </legend>
                      <div className="mt-3 space-y-2">
                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-flier"
                            name="channel"
                            type="radio"
                            value="Flier"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                          <label
                            htmlFor="channel-flier"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Flier
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-sm"
                            name="channel"
                            type="radio"
                            value="Social Media"
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            onChange={handleInputChange}
                          ></input>
                          <label
                            htmlFor="channel-sm"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Social Media
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-fam"
                            name="channel"
                            type="radio"
                            value="Friend/Family"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                          <label
                            htmlFor="channel-fam"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Friend / Family
                          </label>
                        </div>

                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-member"
                            name="channel"
                            type="radio"
                            value="GNM Member"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                          <label
                            htmlFor="channel-member"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            GNM Member
                          </label>
                        </div>

                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-student"
                            name="channel"
                            type="radio"
                            value="Continuing Student"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                          <label
                            htmlFor="channel-student"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Continuing Student
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <fieldset>
                      <legend className="block text font-medium text-gray-700">
                        Are you a new student or current student continuing in a
                        new course?
                      </legend>
                      <div className="mt-3 space-y-2">
                        <div className="flex items-center gap-x-3">
                          <input
                            id="status-new"
                            name="enrollment_status"
                            type="radio"
                            value="new"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                          <label
                            htmlFor="status-new"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            New
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="status-contd"
                            name="enrollment_status"
                            type="radio"
                            value="continuing"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                          <label
                            htmlFor="status-contd"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Continuing
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <div className="flex space-x-2">
                      <div className="flex h-5 items-center">
                        <input
                          id="privacy_policy"
                          name="privacy_policy"
                          type="checkbox"
                          onChange={handlePolicyInput}
                          checked={privacyPolicyStatus}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        ></input>
                      </div>
                      <label
                        htmlFor="privacy_policy"
                        className="text-sm font-medium text-gray-700"
                      >
                        Please read our{" "}
                        <a
                          href="privacy_policy.html"
                          className="text-blue-700"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        . By checking this box, you confirm that you understand
                        how we use your information and agree to the collection
                        of your data for educational purposes.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <button
                  type="submit"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                  disabled={!privacyPolicyStatus}
                >
                  Register
                </button>
                {/* <button
                  type="submit"
                  className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Clear form
                </button> */}
              </div>
              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <svg
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                We adhere to the highest standards of data security in all
                processing and storage of your information.
              </p>
              {/* <div className="justify-between gap-y-4">
                <p className="mb-6 mt-6 flex justify-left text-sm font-medium">
                  Already Registered ?
                </p>
                <Link
                  to="/enroll"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Enroll Here
                </Link>
              </div> */}
            </form>
          )}
        </div>
      </section>
      {/* </main> */}
    </>
  );
};
