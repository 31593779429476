import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../features/auth/authSlice";
import registrationReducer from "../features/registrations/registrationSlice";
import courseReducer from "../features/courses/courseSlice"
import sessionReducer from "../features/sessions/sessionSlice";
import enrollReducer from "../features/enrollment/enrollSlice";
import tutorReducer from "../features/tutors/tutorSlice";
import cashierReducer from "../features/cashiers/cashierSlice";
import attendanceReducer from "../features/attendance_registers/attendanceSlice";
import paymentReducer from "../features/payments/paymentSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    registration: registrationReducer,
    courses: courseReducer,
    sessions: sessionReducer,
    enrollment: enrollReducer,
    tutors: tutorReducer,
    cashiers: cashierReducer,
    attendance: attendanceReducer,
    payments: paymentReducer,
  },
});